












































import Vue from 'vue'
import { mapState } from 'vuex'
import { getOssUrl } from '@/api/common'
import { getConfigValue, setConfigValue } from '@/api/config'
import { getActivityList } from '@/api/activity'
import { pullHotelInfo } from '@/api/green-cloud'
import { pullPMSHotelInfo } from '@/api/pms_cloud'
export default Vue.extend({
	components: {},
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			homeImages: [] as any,
			mallImages: [] as any,
			avatarImages: [] as any,
			showButton: true,
			hideAfter: Date.now(), // 设定好的时间为5秒
			value: '',
		}
	},
	computed: {
		...mapState<any>('admin', {
			admin_info: (state) => state.info,
		}),
	},
	mounted() {
		this.hideButton()
		console.log(this.admin_info, 'admin_info');
		
	},
	methods: {
		hideButton() {
			console.log(new Date('2023-12-01 00:00:00').getTime())
			if (this.hideAfter > new Date('2023-12-01 00:00:00').getTime()) {
				this.showButton = false
			}
		},
		synchronous() {
			let tmiot = Date.now()
			if (tmiot > new Date('2023-12-01 00:00:00').getTime()) {
				this.$message({
					message: '已过系统切换时间暂不可用',
					type: 'warning',
				})
			} else {
				pullHotelInfo().then(() => this.$message.success('保存成功'))
			}
		},
		pullPMSHotel() {
			pullPMSHotelInfo().then(() => this.$message.success('保存成功'))
		},
		getConfig(key) {
			return new Promise((resolve, reject) => {
				getConfigValue({ key: key }).then((res) => {
					resolve(res.data)
				})
			})
		},
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		save(key, value) {
			let base_url_length = this.baseUrl.split('').length
			let valueImage = [] as any
			value.forEach((el) => {
				if (el.indexOf(this.baseUrl) !== -1) {
					el = el.slice(base_url_length)
					if (el.indexOf(this.baseUrl) !== -1) {
						el = el.slice(base_url_length)
					}
				}
				valueImage.push(el)
			})
			setConfigValue({
				key: key,
				value: valueImage,
			}).then((res) => {
				if (res.code == 0) {
					this.$message({
						message: '保存成功！ ',
						type: 'success',
					})
				}
			})
		},
	},
	created() {
		this.getOssUrl()
		this.getConfig('home_banner').then((data) => {
			this.homeImages = data
		})
		this.getConfig('mall_banner').then((data) => {
			this.mallImages = data
		})
		this.getConfig('avatar_banner').then((data) => {
			this.avatarImages = data
		})
	},
})
