






















































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getRoleList, editRole, delRole, getMenuList, setRoleMenus } from '@/api/permission-role'

export default Vue.extend({
	data() {
		return {
			editRole,
			...useTable({
				name: '角色',
				formdata: {
					/** 名称 */
					id: '',
					name: '',
					menu_ids: [] as any,
				},
				getData: (query) => getRoleList(query).then((res) => res.data),
				delData: (row) => delRole({ id: row.id }),
			}),
			checkList: [] as any,
			menuList: [] as any,
			current: '' as any,
		}
	},
	created() {
		this.tableGetData()
		this.getMenu()
	},
	methods: {
		openpermi(row) {
			this.checkList = row.menu_ids
			this.current = row
		},
		getMenu() {
			getMenuList().then((res) => {
				console.log(res)

				this.menuList = res.data.rows.reverse()
			})
		},
		savePermi() {
			setRoleMenus({
				id: this.current.id,
				menu_ids: this.checkList,
			}).then((res) => {
				this.$message.success('保存成功') && this.tableGetData()
			})
		},
	},
})
